<template>
	<div class="z_personInfo">
		<div class="infoBox">
			<div style="display: flex; align-items: center; margin-bottom: 20px">
				<div class="titleIcon"></div>
				<div style="font-size: 18px">基本信息</div>
			</div>

			<div class="baseInfo">
				<div class="baseInfo-flex">
					<div v-for="(item, i) in this.staffBaseInfo" class="item" :key="i">
						<label class="left_label">{{ item.label }}</label>
						<label class="right_label">{{ item.value }}</label>
					</div>
				</div>
			</div>
		</div>
		<div class="infoBox">
			<div style="display: flex; align-items: center; margin-bottom: 20px">
				<div class="titleIcon"></div>
				<div style="font-size: 18px">教育经历</div>
			</div>
			<div v-for="(item, index) in info.staffEducationDtoList" :key="index">
				<div class="moreInfo">
					<label>教育经历{{ index + 1 }}</label>
				</div>
				<div class="baseInfo">
					<div>
						<div>
							<label class="left_label">毕业院校</label>
							<label class="right_label">{{ item.graduatedSchool }}</label>
						</div>
						<div>
							<label class="left_label">专业</label>
							<label class="right_label">{{ item.profession }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">学历</label>
							<label class="right_label">{{ item.educationName }}</label>
						</div>
						<div>
							<label class="left_label">教学方式</label>
							<label class="right_label">{{ item.educationMethodName }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">入学时间</label>
							<label class="right_label">{{ item.admissionTime }}</label>
						</div>
						<div>
							<label class="left_label">毕业时间</label>
							<label class="right_label">{{ item.graduationTime }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">是否第一学历</label>
							<label class="right_label">{{ item.whetherFirEdu == '1' ? '是' : '否' }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="infoBox">
			<div style="display: flex; align-items: center; margin-bottom: 20px">
				<div class="titleIcon"></div>
				<div style="font-size: 18px">工作经历</div>
			</div>
			<div v-for="(item, index) in info.staffWorkExperienceDtoList" :key="index">
				<div class="moreInfo">
					<label>工作经历{{ index + 1 }}</label>
				</div>
				<div class="baseInfo">
					<div style="margin-bottom: 5px">
						<div>
							<label class="left_label">公司名称</label>
							<label class="right_label">{{ item.company }}</label>
						</div>
						<div>
							<label class="left_label">部门</label>
							<label class="right_label">{{ item.departmentName }}</label>
						</div>
					</div>
					<div style="margin-bottom: 5px">
						<div>
							<label class="left_label">岗位</label>
							<label class="right_label">{{ item.officeName }}</label>
						</div>
						<div>
							<label class="left_label">工作开始时间</label>
							<label class="right_label">{{ item.workStartTime }}</label>
						</div>
					</div>
					<div style="margin-bottom: 5px">
						<div>
							<label class="left_label">工作结束时间</label>
							<label class="right_label">{{ item.workEndTime }}</label>
						</div>
						<div>
							<label class="left_label">离职原因</label>
							<label class="right_label" style="line-height: 20px; text-align: justify">{{ item.quitReason }}</label>
						</div>
					</div>
					<div style="margin-bottom: 5px">
						<div>
							<label class="left_label">证明人</label>
							<label class="right_label">{{ item.witness }}</label>
						</div>
						<div>
							<label class="left_label">证明人联系电话</label>
							<label class="right_label">{{ item.witnessContactPhone }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="infoBox">
			<div style="display: flex; align-items: center; margin-bottom: 20px">
				<div class="titleIcon"></div>
				<div style="font-size: 18px">项目经历</div>
			</div>
			<div v-for="(item, index) in info.staffProjectExperienceDtoList" :key="index">
				<div class="moreInfo" style="margin-top: 15px">
					<label>项目经历{{ index + 1 }}</label>
				</div>
				<div class="baseInfo">
					<div>
						<div>
							<label class="left_label">项目名称</label>
							<label class="right_label">{{ item.proName }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">项目开始时间</label>
							<label class="right_label">{{ item.proStartTime }}</label>
						</div>
						<div>
							<label class="left_label">项目结束时间</label>
							<label class="right_label">{{ item.proEndTime }}</label>
						</div>
					</div>
					<div>
						<div class="row_label flex-row">
							<label class="left_label">项目介绍</label>
							<label class="right_label" style="text-align: justify">{{ item.proIntrod }}</label>
						</div>
					</div>
					<div>
						<div class="row_label flex-row">
							<label class="left_label">职责描述</label>
							<label class="right_label" style="text-align: justify">{{ item.proDuties }}</label>
						</div>
					</div>
					<div>
						<div class="row_label flex-row">
							<label class="left_label">主要业绩</label>
							<label class="right_label" style="text-align: justify">{{ item.proPerfor }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="infoBox">
			<div style="display: flex; align-items: center; margin-bottom: 20px">
				<div class="titleIcon"></div>
				<div style="font-size: 18px">培训记录</div>
			</div>
			<div v-for="(item, index) in info.staffTrainingRecordsDtoList" :key="index">
				<div class="moreInfo">
					<label>培训记录{{ index + 1 }}</label>
				</div>
				<div class="baseInfo">
					<div>
						<div>
							<label class="left_label">培训课程</label>
							<label class="right_label">{{ item.traCourse }}</label>
						</div>
						<div>
							<label class="left_label">培训开始时间</label>
							<label class="right_label">{{ item.traStartTime }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">工作结束时间</label>
							<label class="right_label">{{ item.traEndTime }}</label>
						</div>
						<div>
							<label class="left_label">培训时长</label>
							<label class="right_label">{{ item.traTime }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">培训机构名称</label>
							<label class="right_label">{{ item.traOrgName }}</label>
						</div>
						<div>
							<label class="left_label">考核结果</label>
							<label class="right_label">{{ item.exaResults }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">培训地点</label>
							<label class="right_label">{{ item.traAddress }}</label>
						</div>
						<div>
							<label class="left_label">培训方式</label>
							<label class="right_label" style="line-height: 20px">{{ item.traMode }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="infoBox">
			<div style="display: flex; align-items: center; margin-bottom: 20px">
				<div class="titleIcon"></div>
				<div style="font-size: 18px">家庭状况</div>
			</div>
			<div v-for="(item, index) in info.staffRelativesContactInfoList" :key="index">
				<div class="moreInfo">
					<label>家庭状况{{ index + 1 }}</label>
				</div>
				<div class="baseInfo">
					<div>
						<div>
							<label class="left_label">姓名</label>
							<label class="right_label">{{ item.name }}</label>
						</div>
						<div>
							<label class="left_label">关系</label>
							<label class="right_label">{{ item.relationshipName }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">紧急联系人与否</label>
							<label class="right_label">{{ item.urgentName }}</label>
						</div>
						<div>
							<label class="left_label">联系电话1</label>
							<label class="right_label">{{ item.telOne }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">联系电话2</label>
							<label class="right_label">{{ item.telTwo }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="infoBox">
			<div style="display: flex; align-items: center; margin-bottom: 20px">
				<div class="titleIcon"></div>
				<div style="font-size: 18px">证照材料</div>
			</div>
			<div v-for="(item, index) in imgInfo.responseList" :key="index" style="margin-bottom: 10px">
				<div class="moreInfo">
					<label>证照{{ index + 1 }}</label>
					<!-- <div class="editBtn" style="display: inline-block">
						<el-button @click="handleEdit(JSON.stringify(item))" type="text">编辑</el-button>
						<el-button @click="handleDel(item)" style="color: #f56c6c" type="text">删除</el-button>
					</div> -->
				</div>
				<div class="baseInfo">
					<div>
						<div>
							<label class="left_label">证照类型</label>
							<label class="right_label">{{ item.cerTypeName }}</label>
						</div>
						<!-- <div>
                      <label class="left_label">证照名称</label>
                      <label class="right_label">{{item.licenseName}}</label>
                  </div>-->
						<div>
							<!-- <label class="left_label" v-if="item.cerType == '1'">身份证号码</label> -->
							<!-- <label class="left_label" v-if="item.cerType != '1'">证照编码</label> -->
							<label class="right_label">{{ item.licenseId }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">证照</label>
							<label class="right_label">
								<span
									@click="downloadModal({ url: img, bucketName: item.cerTypeName, name: item.cerTypeName, key: idx })"
									class="avatar"
									v-for="(img, idx) in imgSrcList(item)"
									:key="idx"
								>
									<img :src="img" />
								</span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<!-- <div v-for="(item, index) in info.staffCertificateRecordList" :key="index">
				<div class="moreInfo">
					<label>证照材料{{ index + 1 }}</label>
				</div>
				<div class="baseInfo">
					<div>
						<div>
							<label class="left_label">证书名称</label>
							<label class="right_label">{{ item.cerName }}</label>
						</div>
						<div>
							<label class="left_label">等级</label>
							<label class="right_label">{{ item.grade }}</label>
						</div>
					</div>
					<div>
						<div>
							<label class="left_label">分数</label>
							<label class="right_label">{{ item.score || '' }}</label>
						</div>
						<div>
							<label class="left_label">颁发时间</label>
							<label class="right_label">{{ item.startTime }}</label>
						</div>
					</div>
				</div>
			</div> -->
		</div>
		<el-dialog :visible.sync="downLoadVisible" title="下载证照" center @close="downLoadVisible = false">
			<div id="imgWrap">
				<img id="imgFile" width="100%" :src="downLoadUrl" alt />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="downLoad" type="primary">下载</el-button>
				<el-button type="primary" v-print="'#imgFile'">打印</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { downloadByCreateA, waterMark } from '@/utils/util';

export default {
	props: {
		staffinfo: {}
	},
	watch: {
		staffinfo(newVal) {
			this.info = newVal;
			for (const item of this.staffBaseInfo) {
				item.value = newVal.staffBasicInfoDto[item.name];
			}
		},
		staffBasicInfoDto(val) {
			if (val.staffId) {
				this.getDetailImgList(val.staffId);
			}
		}
	},
	computed: {
		imgSrcList() {
			return (item) =>
				[
					item.frontOfIdCard,
					item.reverseOfIdCard,
					item.eduHisCertificate,
					item.degreeCertificate,
					item.idPhoto,
					item.lifePhoto,
					item.licenseUrl
				]
					.filter((v) => v)
					.join('|')
					.split('|');
		},
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		},
		staffBasicInfoDto() {
			return this.staffinfo.staffBasicInfoDto || {};
		}
	},
	data() {
		return {
			info: [],
			staffBaseInfo: [
				{ name: 'name', label: '姓名', value: '' },
				{ name: 'identityNum', label: '身份证号', value: '' },
				{ name: 'sexName', label: '性别', value: '' },
				{ name: 'maritalName', label: '婚姻状况', value: '' },
				{ name: 'profession', label: '专业', value: '' },
				{ name: 'graduatedSchool', label: '毕业院校', value: '' },
				{ name: 'birthday', label: '出生日期', value: '' },
				{ name: 'phoneNumber', label: '手机号码', value: '' },
				{ name: 'age', label: '年龄', value: '' },
				{ name: 'mailbox', label: '个人邮箱', value: '' },
				{ name: 'highestEducationName', label: '学历', value: '' },
				{ name: 'homeAddress', label: '家庭住址', value: '' },
				{ name: 'nativePlace', label: '籍贯', value: '' },
				{ name: 'emergencyContact', label: '紧急联系人', value: '' },
				{ name: 'politicalName', label: '政治面貌', value: '' },
				{ name: 'emergencyContactPhone', label: '紧急联系人电话', value: '' }
			],
			activeNames: '1',
			imgInfo: {},
			downLoadVisible: false,
			downLoadUrl: ''
		};
	},
	mounted() {},
	methods: {
		downLoad() {
			downloadByCreateA(this.downLoadUrl);
		},
		getDetailImgList(id) {
			this.$axios.post('/v1/org/queryLicence/detail', { request: { id, type: '2' } }).then((res) => {
				this.imgInfo = res;
			});
		},
		// 下载打印dialog
		downloadModal(img) {
			this.downLoadVisible = true;
			this.downLoadUrl = img.url;
			this.$nextTick(() => {
				// 加水印
				waterMark({ text: this.userInfo.nickName, container: document.querySelector('#imgWrap') });
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.infoBox {
	background: #fff;
	margin: 15px;
	padding: 15px 30px;
	.moreInfo {
		border-top: 1px solid #cccccc;

		font-size: 14px;

		font-weight: bold;

		margin: 0px 15px;

		padding-top: 15px;

		position: relative;

		label {
			margin-top: 10px;
		}

		.editBtn {
			position: absolute;

			top: 8px;

			right: 0;
		}
	}
}
.infoBox:first-of-type {
	margin-top: 0;
}
.titleIcon {
	font-size: 20px;
	&:before {
		display: inline-block;
		width: 8px;
		height: 22px;
		background: #498df0;
		content: ' ';
		border-radius: 5px;
		vertical-align: top;
		margin-right: 5px;
	}
}
@mixin label {
	.left_label {
		margin-right: 30px;
		color: #999999;
		text-align: right;
		width: 130px;
		vertical-align: top;
	}
	.right_label {
		width: 50%;
		text-align: left;
		.avatar {
			display: inline-block;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			text-align: center;
			color: #fff;
			background: #c0c4cc;
			width: 40px;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
			height: 50px;
			width: 50px;
			line-height: 50px;
			margin-left: 5px;
			overflow: hidden;
			border-radius: 4px;
			&:hover {
				cursor: pointer;
			}
			img {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
	}
	label {
		font-weight: normal;
		display: inline-block;
	}
}
.baseInfo-flex {
	text-align: center;
	font-size: 14px;
	display: flex;
	flex-wrap: wrap;
	@include label;
	width: 100%;
	.item {
		min-height: 50px;
		flex: 50%;
	}
}
.baseInfo {
	text-align: center;
	font-size: 14px;
	.editBtn {
		text-align: right;
		padding-right: 25px;
	}
	div {
		min-height: 50px;
		overflow: hidden;
		div {
			width: calc(100% / 2);
			overflow: hidden;
			float: left;
			&::after {
				visibility: hidden;
				display: block;
				font-size: 0;
				content: ' ';
				clear: both;
				height: 0;
			}
		}
		.row_label {
			width: 100%;
			height: auto;
			box-sizing: border-box;
			margin-top: 10px;
			display: flex;
			margin: 10px auto 0;
			.left_label {
				width: 17%;
				line-height: 1.5em;
				vertical-align: top;
			}
			.right_label {
				padding-right: 30px;
				box-sizing: border-box;
				line-height: 1.5em;
				width: calc(100% - 160px);
				word-break: break-all;
			}
		}
		.flex-row.row_label {
			text-align: right;
		}
	}
	@include label;
}
.contractInfo div div {
	width: calc(100% / 3);
}
</style>
<style lang="scss">
.z_personInfo {
	margin: 0 0 10px;
}
</style>
