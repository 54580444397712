<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-08 21:43:15
 * @LastEditTime: 2020-04-28 10:00:21
 -->
<template>
	<div class="z-Info">
		<el-tabs class="infoTab" v-model="activeName">
			<el-tab-pane label="个人信息" name="first" v-if="permissionControl('PersonalStaffInfo')">
				<staffinfo :staffinfo="staffinfo" pageName="PersonalStaffInfo"></staffinfo>
			</el-tab-pane>
			<el-tab-pane label="岗位信息" name="second" v-if="permissionControl('JobInfo')">
				<staffjobinfo :jobinfo="jobinfo" pageName="JobInfo"></staffjobinfo>
			</el-tab-pane>
			<el-tab-pane label="成长记录" name="third" v-if="permissionControl('GrowthRecord')">
				<div class="viewbox" style="background: #fff; margin: 0 15px 15px 15px">
					<timeline :growthRecord="growthRecord" pageName="GrowthRecord"></timeline>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
import * as api from '@/api/loginInfo';
import * as getStaff from '@/api/workbench';
import staffinfo from '@/components/staffSystem/staffInfo';
import staffjobinfo from '@/components/staffSystem/staffJobInfo';
import timeline from '@/components/humanafairs/timeLine';
export default {
	components: {
		staffinfo,
		staffjobinfo,
		timeline
	},
	computed: {
		pageName() {
			return this.$route.name;
		},
		permissionList() {
			return this.$store.state.app.menusPermission;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.getStaff();
			this.setActive();
		});
	},
	data() {
		return {
			staffinfo: {},
			jobinfo: {},
			growthRecord: [],
			activeName: 'first'
		};
	},
	methods: {
		setActive() {
			const data = this.permissionList.find(({ component }) => this.pageName === component);

			if (data && data.children?.length) {
				const row = data.children[0];

				switch (row.component) {
					case 'PersonalStaffInfo':
						this.activeName = 'first';
						break;
					case 'JobInfo':
						this.activeName = 'second';
						break;
					case 'GrowthRecord':
						this.activeName = 'third';
						break;

					default:
						break;
				}
			}
		},
		getStaff() {
			api.getUserInfo({ request: {} }).then((res) => {
				this.staffId = res.staffId;
				this.$store.commit('app/SET_STAFFID', res.staffId);
				this.getStaffInfo();
			});
		},
		getStaffInfo() {
			getStaff.getStaffInfoDetail({ request: { id: this.staffId } }).then((res) => {
				this.staffinfo = res;
			});
			getStaff.getStaffJobInfoDetail({ request: { id: this.staffId } }).then((res) => {
				this.jobinfo = res;
			});
			getStaff
				.getGrowthRecord({
					request: {
						id: this.staffId
					}
				})
				.then((result) => {
					this.growthRecord = result;
				});
		}
	}
};
</script>
<style lang="scss">
.z-Info {
	.infoTab .el-tabs__header {
		padding: 10px 30px;
	}
}
</style>
