<template>
  <div>
    <div class="infoBox">
      <div style="display:flex;align-items: center;margin-bottom:20px;">
        <div class="titleIcon"></div>
        <div style="font-size:18px;">基本信息</div>
      </div>
      <div class="baseInfo">
        <div>
          <div>
            <label class="left_label">入职日期</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.entryTime}}</label>
          </div>
          <div>
            <label class="left_label">工作邮箱</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.businessMailbox}}</label>
          </div>
        </div>
        <div>
          <div>
            <label class="left_label">转正日期</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.turnPositiveTime}}</label>
          </div>
          <div>
            <label class="left_label">转正状态</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.statusName}}</label>
          </div>
        </div>
        <div>
          <div>
            <label class="left_label">聘用形式</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.hireFormName}}</label>
          </div>
          <div>
            <label class="left_label">工号</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.jobNumber}}</label>
          </div>
        </div>
        <div>
          <div>
            <label class="left_label">职类</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.className}}</label>
          </div>
          <div>
            <label class="left_label">职位</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.officeName}}</label>
          </div>

        </div>
        <div>
          <div>
            <label class="left_label">职级</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.familyCode}}</label>
          </div>
          <div>
            <label class="left_label">等级</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.level}}</label>
          </div>
        </div>
        <div>
          <div>
            <label class="left_label">组织部门</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.orgName}}</label>
          </div>
          <div>
            <label class="left_label">职务</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.positionName}}</label>
          </div>
        </div>
        <div>
          <div>
            <label class="left_label">岗位</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.jobName}}</label>
          </div>
          <div>
            <label class="left_label">汇报对象</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.repObjName}}</label>
          </div>
        </div>
        <div>
          <div>
            <label class="left_label">直属下属数量</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.number}}</label>
          </div>
          <div>
            <label class="left_label">成本中心</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.centerName}}</label>
          </div>
        </div>
        <div>
          <div>
            <label class="left_label">工作城市</label>
            <label class="right_label">
              {{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.workProvinceName}} &nbsp; {{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.workCityName}}
            </label>
          </div>
          <div>
            <label class="left_label">首次参加工作时间</label>
            <label class="right_label">{{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.firstWorkTime}}</label>
          </div>
        </div>
        <div>
          <div>
            <label class="left_label">社保公积金城市</label>
            <label class="right_label">
              {{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.hpfProvinceName}} &nbsp; {{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.hpfCityName}}
            </label>
          </div>
        </div>
        <div>
          <div style="width:100%">
            <label class="left_label">职责</label>
            <label class="right_label"
                   style="width:75%;line-height:20px;text-align:justify;">
              {{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.duty}}
            </label>
          </div>
        </div>
        <div>
          <div style="width:100%">
            <label class="left_label">主要业务</label>
            <label class="right_label"
                   style="width:75%;line-height:20px;text-align:justify;">
              {{info.staffBasicJobDetailDto && info.staffBasicJobDetailDto.business}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="infoBox">
      <div style="display:flex;align-items: center;margin-bottom:20px;">
        <div class="titleIcon"></div>
        <div style="font-size:18px;">合同信息</div>
      </div>
      <div v-for="(item,index) in info.staffContractRecordDetailDtoList"
           :key="index">
        <div class="moreInfo">
          <label>合同信息{{index + 1}}</label>
        </div>
        <div class="baseInfo contractInfo">
          <div>
            <div>
              <label class="left_label">公司名称</label>
              <label class="right_label">{{item.companyName}}</label>
            </div>
            <div>
              <label class="left_label">现合同开始时间</label>
              <label class="right_label">{{item.contractStartTime}}</label>
            </div>
            <div>
              <label class="left_label">现合同结束时间</label>
              <label class="right_label">{{item.contractEndTime}}</label>
            </div>
          </div>
          <div>
            <div>
              <label class="left_label">合同期限</label>
              <label class="right_label">{{item.deadlineName}}</label>
            </div>
            <div>
              <label class="left_label">续签次数</label>
              <label class="right_label">{{item.renewNumber}}</label>
            </div>
          </div>
          <div style="min-height:60px;">
            <div style="width:100%;text-align:left">
              <label class="left_label">合同文件</label>
              <label class="right_label"
                     style="width:80%">
                <div class="docContainer"
                     style="width:100%;display: flex;">
                  <div class="docContent"
                       style="width:auto"
                       v-for="(file,index) in item.fileUrlList"
                       :key="index">
                    <img src="/static/images/fj.png"
                         style="width:40px;height:40px;margin-right:5px">
                    <div style="width:auto;">
                      <span style="diisplay:inline-block;transform:translateY(2px)">{{file.name}}({{file.size}})</span>
                      <br />
                      <a style="color:#409EFF;margin-right:5px;font-size:12px"
                         target='_blank'
                         :href="'http://view.officeapps.live.com/op/view.aspx?src='+file.url">预览</a>
                      <el-button type="text" size="small"
                                 @click="downLoadFile(file.url)">下载</el-button>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    jobinfo: {}
  },
  watch: {
    jobinfo (newVal) {
      this.info = newVal;
    }
  },
  data () {
    return {
      info: [],
      activeNames: '1'
    };
  },
  methods: {
    downLoadFile (url) {
      window.open(url);
    }
  }
};
</script>

<style lang="scss" scoped>
.infoBox {
  background: #fff;
  margin: 15px;
  padding: 15px 30px 50px;
  .moreInfo {
      border-top: 1px solid #ccc;
    padding: 15px;
  }
}
.infoBox:first-of-type {
  margin-top: 0;
}
.contractInfo {
  text-align: unset !important;
  padding: 15px 30px;
}
.docContainer {
  display: flex;
  .docContent {
    display: flex;
    align-items: center;
    margin-left: 10px;
    flex-wrap: wrap;
    min-width: 140px;
    img {
      transform: translateY(-10px)
    }
  }
}
.titleIcon {
  display: inline-block;
  width: 8px !important;
  height: 20px;
  background: #498df0;
  border-radius: 5px;
  vertical-align: bottom;
  margin-right: 5px !important;
}
.baseInfo {
  text-align: center;
  font-size: 14px;
  .editBtn {
    text-align: right;
    padding-right: 25px;
  }
  div {
    min-height: 60px;
     &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
    div {
      width: calc(100% / 2);
      float: left;
    }
    .row_label {
      width: 100%;
      height: auto;
      margin-top: 10px;
      .left_label {
        width: 5%;
        vertical-align: top;
      }
      .right_label {
        width: 70%;
        word-break: break-all;
      }
    }
  }
  .left_label {
    margin-right: 30px;
    color: #999999;
    text-align: right;
    width: 130px;
    vertical-align: top;
  }
  .right_label {
    width: 50%;
    padding: 0 0 10px;
    margin-top: -3px;
    text-align: left;
  }
  label {
    font-weight: normal;
    display: inline-block;
  }
}
.contractInfo div div {
  width: calc(100% / 3);
}
</style>

